import React from 'react';
import { Link } from 'react-router-dom';
import Bank from 'Root/images/bank.jpg';
import AlGoannaCollection from 'Root/images/collections/algoanna-collection.jpg';
import MngoCollection from 'Root/images/collections/mngo-collection.jpg';
import MostlyFrensCollection from 'Root/images/collections/mostlyfrens-collection.jpg';
import MutantsCollection from 'Root/images/collections/mutantsvszombies-collection.jpg';
import StupidhorseCollection from 'Root/images/stupidhorse-collection.png';
import LostBotsCollection from 'Root/images/collections/lostbots-collection.png';
import LostPigsCollection from 'Root/images/collections/lostpigs-collection.jpg';
import crazyGooseCollection from 'Root/images/collections/cgf-collection.jpg';

const StatCard = ({ number, label }) => (
  <div className="flex flex-col items-center bg-black/20 backdrop-blur-lg rounded-xl p-4 md:p-6 border border-white/5">
    <span className="text-3xl md:text-5xl font-bold text-dark-brand dark:text-mutant-brand mb-2">{number}</span>
    <span className="text-xs md:text-sm uppercase tracking-wider text-white/70">{label}</span>
  </div>
);

const CollectionUpdate = ({ image, name, oldAmount, newAmount, oldReward, newReward }) => (
  <div className="bg-dark-table/50 backdrop-blur-sm rounded-xl overflow-hidden">
    <img src={image} alt={name} className="w-full h-36 md:h-48 object-cover" />
    <div className="p-3 md:p-4 space-y-2">
      <h3 className="text-lg md:text-xl font-semibold text-white">{name}</h3>
      <div className="grid grid-cols-2 gap-3 md:gap-4">
        <div>
          <p className="text-white/70 text-sm md:text-base">Loan Amount</p>
          <p className="text-white text-sm md:text-base">
            <span className="line-through text-white/50">{oldAmount}A</span>
            {' → '}
            <span className="text-dark-brand">{newAmount}A</span>
          </p>
        </div>
        {oldReward && newReward && (
          <div>
            <p className="text-white/70 text-sm md:text-base">Reward</p>
            <p className="text-white text-sm md:text-base">
              <span className="line-through text-white/50">{oldReward}</span>
              {' → '}
              <span className="text-mutant-brand">{newReward} GOANNA</span>
            </p>
          </div>
        )}
      </div>
    </div>
  </div>
);

const WelcomeSection = () => {
  document.title = 'Goanna Lending';

  return (
    <div className="min-h-screen rounded-b-lg bg-black/10 backdrop-blur-2xl">
      <div className="relative">
        <div className="relative">
          <img src={Bank} className="w-full h-[40vh] md:h-[50vh] object-cover" alt="Goanna Bank" />
          <div className="absolute inset-0 bg-black/60"></div>
          <Link to="/analytics" className="absolute bottom-4 md:bottom-8 right-4 md:right-8 px-4 md:px-6 py-2 md:py-3 bg-dark-table/70 hover:bg-dark-brand text-white text-sm font-medium rounded-lg transition-all duration-300 hidden md:block">
            Enter Bank
          </Link>
          {/* Updates positioned at top left */}
          <div className="absolute top-4 md:top-8 left-4 md:left-8 p-4 md:p-8 bg-gradient-to-r from-black/30 to-transparent">
            <h2 className="text-2xl md:text-3xl font-bold text-dark-brand mb-1"><span className="text-white/90 text-sm md:text-md">Happy&nbsp;</span>&nbsp;New Year&nbsp;<span className="text-white/90 text-xs md:text-sm">&nbsp;From&nbsp;</span></h2>
            <h2 className="text-2xl md:text-3xl font-bold text-dark-brand mb-1">The Goanna Council</h2>
            <p className="text-white/90 text-sm md:text-base mb-4 md:mb-6 strong">Hey Goannas! We've been cooking up some changes for 2025</p>
            <ul className="space-y-2 md:space-y-4">
              <li className="text-sm md:text-base text-white/90 hover:text-white transition-colors pl-4">
                🦎Quick and easy - loans now just 30 days!
              </li>
              <li className="text-sm md:text-base text-white/90 hover:text-white transition-colors pl-4">
                🦎Sweet deal - Effective interest rate 5% for 30 days.
              </li>
              <li className="text-sm md:text-base text-white/90 hover:text-white transition-colors pl-4">
                🦎More GOANNA rewards for the win!
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-4 py-8 md:py-16 space-y-8 md:space-y-16">
        {/* 2024 Statistics */}
        <div className="text-center space-y-6 md:space-y-8">
          <h2 className="text-3xl md:text-4xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-dark-brand to-mutant-brand">
            Here's Our 2024 Recap
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 md:gap-8">
            <StatCard number="1,000+" label="Loans Provided" />
            <StatCard number="1M+" label="ALGO Value Created" />
            <StatCard number="5K+" label="GOANNA Cashback Rewards" />
          </div>
        </div>

        {/* Collection Updates */}
        <div className="space-y-6 md:space-y-8">
          <h2 className="text-3xl md:text-4xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-dark-brand to-mutant-brand text-center">
            2025 Collection Updates
          </h2>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-6">
            <CollectionUpdate
              image={AlGoannaCollection}
              name="Al Goanna"
              oldAmount="2000"
              newAmount="2000"
              oldReward="2466"
              newReward="5000"
            />
            <CollectionUpdate
              image={MutantsCollection}
              name="Mutants vs Zombies"
              oldAmount="50"
              newAmount="50"
              oldReward="61.5"
              newReward="125"
            />
            <CollectionUpdate
              image={StupidhorseCollection}
              name="Stupid Horse"
              oldAmount="500"
              newAmount="275"
              oldReward="369"
              newReward="0"
            />
            <CollectionUpdate
              image={MostlyFrensCollection}
              name="Mostly Frens"
              oldAmount="200"
              newAmount="125"
              oldReward="0"
              newReward="0"
            />
            <CollectionUpdate
              image={LostBotsCollection}
              name="The Lost Bots"
              oldAmount="50"
              newAmount="75"
              oldReward="0"
              newReward="0"
            />
            <CollectionUpdate
              image={MngoCollection}
              name="MNGO"
              oldAmount="300"
              newAmount="175"
              oldReward="0"
              newReward="0"
            />
            <CollectionUpdate
              image={LostPigsCollection}
              name="The Lost Pigs"
              oldAmount="300"
              newAmount="275"
              oldReward="0"
              newReward="0"
            />
            <CollectionUpdate
              image={crazyGooseCollection}
              name="Crazy Goose Flock"
              oldAmount="50"
              newAmount="40"
              oldReward="0"
              newReward="0"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WelcomeSection;
