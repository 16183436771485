import { filterAssets, filterApp, filterAccount } from './filters'
import { getCreatedAppsObject } from './formatters'

async function getAccountInfo(algodClient, address, filter) {
  try {
    const acc = await algodClient.accountInformation(address).do();
    if (!filter) return acc;
    return filterAccount(filter, acc);
  } catch (error) {
    console.error('getAccountInfo ', error.message);
    throw error
  }
}
async function getApplication(algodClient, appId, filter) {
  try {
    const app = await algodClient.getApplicationByID(appId).do();
    if (!filter) return app;
    return filterApp(filter, app);
  } catch (error) {
    console.error('getApplication ', error.message);
  }
}
async function getAccountAssets(algodClient, address, filters) {
  try {
    const res = await getAccountInfo(algodClient, address);
    const assets = res['assets'];
    if (!filters) {
      return assets;
    }
    // return filters.reduce((result, filter) => filterAssets(filter, result), assets)
    return filters
      .map(filter => filterAssets(filter, assets))
      .flatMap(assetz => assetz);
  } catch (error) {
    console.error('getAccountAssets ', error.message);
    return [];
  }
}
async function getCreatedApps(algodClient, address, filters) {
  try {
    const res = await getAccountInfo(algodClient, address);
    const createdApps = getCreatedAppsObject(res)
    if (!filters) {
      return createdApps;
    }
    return filters
      .map(filter => filterAssets(filter, assets))
      .flatMap(assetz => assetz);
  } catch (error) {
    console.error('getAccountAssets ', error.message);
    return [];
  }
}
async function getCollectionAccountAssets(
  algodClient,
  address,
  filters,
  unitNames
) {
  try {
    console.log("Getting account info")
    const res = await getAccountInfo(algodClient, address);
    const assets = res['created-assets'];
    if (!filters) {
      return assets;
    }
    return filters
      .map(filter => filterAssets(filter, assets, unitNames))
      .flatMap(assetz => assetz);
  } catch (error) {
    console.error('getAccountAssets ', error.message);
    return [];
  }
}
async function getAssetById(algodClient, asset) {
  try {
    return await algodClient.getAssetByID(asset).do();
  } catch (error) {
    console.error('getAssetById ', error.message);
    return undefined
  }
};
async function getTransactions(appId) {
  try {
    const response = await fetch(
      `https://mainnet-idx.algonode.cloud/v2/transactions?tx-type=appl&application-id=${appId}`
    );
    const { transactions } = await response.json()
    return transactions
  } catch (error) {
    console.error('getTransactions', error.message);
    return [];
  }
}
async function getAssetDataFromPera(index) {
  try {
    const response = await fetch(
      `https://mainnet.api.perawallet.app/v1/public/assets/${index}/`,
      { timeout: 3000 }
    );
    const data = await response.json()
    return data;
  } catch (error) {
    console.log('getAssetDataFromPera: ', error.message);
    return undefined;
  }
}
export {
  getAccountInfo,
  getAccountAssets,
  getTransactions,
  getApplication,
  getAssetDataFromPera,
  getAssetById,
  getCollectionAccountAssets,
  getCreatedApps
};
