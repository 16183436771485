import { MenuButton, MenuItem, MenuItems, Menu, Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react'
import { calcEndDateG, calcPriceG, FILTERS, getLendAmountG, getLendDate, getLendTime, getLendTimeG, getPaid, getPaidG, getPayback } from '../../helpers';
import InfiniteScroll from 'react-infinite-scroll-component';
import { NFTCardSkeleton } from '../Skeletons/nftCardSkeleton';
import { AlertTriangle, ChevronDown } from 'lucide-react';
import NFTCard from './NFTCard';
import { useEffect, useState } from 'react';
import useIsSticky from 'Hooks/useIsSticky';
import { useWallet } from '@txnlab/use-wallet';
import Nothing from '../Sections/NotFoundSection/Nothing';
export default function NftTabGrid({
  filteredAssets,
  categorizedLoans,
  assetFilters,
  setAssetFilters,
  loading,
  setLoanFilters,
  loanFilters
}) {
  const { assetTabHeight, isSticky, tabHeight } = useIsSticky()
  const [selectedIndex, setSelectedIndex] = useState(0)
  const { activeAddress } = useWallet()
  const tabs = [
    {
      name: `Eligible Assets (${filteredAssets?.length | 0})`,
      count: filteredAssets?.length | 0,
      warning: false
    },
    {
      name: `Active Loans (${categorizedLoans?.active.length | 0})`,
      count: categorizedLoans?.active.length | 0,
      warning: false
    },
    {
      name: `Overdue (${categorizedLoans?.overdue.length | 0})`,
      count: categorizedLoans?.overdue.length | 0,
      warning: categorizedLoans?.overdue.length > 0 | false
    },
    {
      name: `History (${categorizedLoans?.history.length | 0})`,
      count: categorizedLoans?.history.length | 0,
      warning: false
    }
  ]

  useEffect(() => {
    const longestContentIndex = [filteredAssets, categorizedLoans.active, categorizedLoans.overdue].reduce((maxIndex, current, currentIndex, array) => {
      return current.length > array[maxIndex].length ? currentIndex : maxIndex;
    }, 0);
    setSelectedIndex(longestContentIndex)
  }, [])
  return (
    <TabGroup selectedIndex={selectedIndex} onChange={setSelectedIndex} className={"w-full max-w-screen-lg "} style={{ height: tabHeight && isSticky ? 'unset' : 'unset', overflowY: 'hidden' }}>
      <TabList id="Asset_Tab" className="hidden sm:flex flex-col sm:flex-row gap-4">
        <Tab className="rounded-full py-1 px-3 text-sm/6 font-semibold text-white focus:outline-none data-[selected]:bg-white/10 data-[hover]:bg-white/5 data-[selected]:data-[hover]:bg-white/10 data-[focus]:outline-1 data-[focus]:outline-white">
          Eligible Assets ({filteredAssets.length})
        </Tab>
        <Tab className="rounded-full py-1 px-3 text-sm/6 font-semibold text-white focus:outline-none data-[selected]:bg-white/10 data-[hover]:bg-white/5 data-[selected]:data-[hover]:bg-white/10 data-[focus]:outline-1 data-[focus]:outline-white">
          Active Loans ({categorizedLoans.active.length})
        </Tab>
        <Tab className={`rounded-full py-1 px-3 text-sm/6 font-semibold text-white focus:outline-none data-[selected]:bg-white/10 data-[hover]:bg-white/5 data-[selected]:data-[hover]:bg-white/10 data-[focus]:outline-1 data-[focus]:outline-white
      ${categorizedLoans.overdue.length > 0 ? 'bg-red-600' : ''}`}>
          Overdue ({categorizedLoans.overdue.length})
          {categorizedLoans.overdue.length > 0 && (
            <AlertTriangle className="ml-2 inline-block h-4 w-4" />
          )}
        </Tab>
        <Tab className="rounded-full py-1 px-3 text-sm/6 font-semibold text-white focus:outline-none data-[selected]:bg-white/10 data-[hover]:bg-white/5 data-[selected]:data-[hover]:bg-white/10 data-[focus]:outline-1 data-[focus]:outline-white">
          History ({categorizedLoans.history.length})
        </Tab>
      </TabList>
      <Menu as='div' className="relative sm:hidden">
        <MenuButton as='div' className="flex w-full items-center justify-between rounded-lg bg-white/10 px-3 py-2 text-sm font-semibold text-white hover:bg-white/20">
          <span>{tabs[selectedIndex].name}</span>
          <ChevronDown className="ml-2 h-4 w-4" />
        </MenuButton>
        <MenuItems className="absolute z-10 mt-2 w-full rounded-lg bg-white/10 p-1 shadow-lg focus:outline-none backdrop-blur">
          {tabs.map((tab, idx) => (
            <MenuItem key={tab.name}>
              {({ focus }) => {
                if (focus) {
                  setSelectedIndex(idx)
                }
                return <div
                  className={`${focus ? 'bg-white/10' : ''} flex items-center justify-between rounded-md px-3 py-2 text-sm text-white`}>
                  {tab.name}
                  {tab.warning && (
                    <AlertTriangle className="ml-2 h-4 w-4 text-red-500" />
                  )}
                </div>
              }}
            </MenuItem>
          ))}
        </MenuItems>
      </Menu>
      <TabPanels className="mt-3">
        {/* Eligible Assets Panel */}
        <TabPanel className="rounded-xl bg-white/5 p-3" style={{ height: assetTabHeight && isSticky ? 'unset' : 'unset', overflowY: 'auto' }}>
          <div className="mb-4 flex gap-4 overflow-y-auto">
            <select
              className="w-52 origin-top-right rounded-tab border border-white/5 bg-white/5 p-1 text-xs/6 text-white transition duration-100 ease-out [--anchor-gap:var(--spacing-1)] focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0 cursor-pointer"
              value={assetFilters.loanLength}
              onChange={(e) => setAssetFilters(prev => ({ ...prev, loanLength: e.target.value }))}
            >
              <option value="all">Loan Length: All</option>
              {FILTERS.assets.loanLength.ranges.slice(1).map(range => (
                <option key={range.value} value={range.value}>
                  {range.label}
                </option>
              ))}
            </select>

            <select
              className="w-52 origin-top-right rounded-tab border border-white/5 bg-white/5 p-1 text-xs/6 text-white transition duration-100 ease-out [--anchor-gap:var(--spacing-1)] focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0 cursor-pointer"
              value={assetFilters.loanAmount}
              onChange={(e) => setAssetFilters(prev => ({ ...prev, loanAmount: e.target.value }))}
            >
              <option value="all">Loan Amount: All</option>
              {FILTERS.assets.loanAmount.ranges.slice(1).map(range => (
                <option key={`${range.min}-${range.max}`} value={`${range.min}-${range.max}`}>
                  {range.label}
                </option>
              ))}
            </select>

            <select
              className="w-52 origin-top-right rounded-tab border border-white/5 bg-white/5 p-1 text-xs/6 text-white transition duration-100 ease-out [--anchor-gap:var(--spacing-1)] focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0 cursor-pointer"
              value={assetFilters.apr}
              onChange={(e) => setAssetFilters(prev => ({ ...prev, apr: e.target.value }))}
            >
              <option value="all">APR: All</option>
              {FILTERS.assets.apr.ranges.slice(1).map(range => (
                <option key={`${range.min}-${range.max}`} value={`${range.min}-${range.max}`}>
                  {range.label}
                </option>
              ))}
            </select>
          </div>
          <InfiniteScroll dataLength={filteredAssets?.length || 0} hasMore={false} className="grid grid-cols-1 gap-4 sm:grid-cols-3 lg:grid-cols-4">
            {!loading && filteredAssets?.length == 0 && (
              <Nothing pt={0} t={0} position="relative" />
            )}
            {
              loading && Array.from({ length: 3 }, (_, index) => (
                <NFTCardSkeleton key={index} />
              ))
            }
            {
              !loading && filteredAssets.map(asset => {
                return (
                  <NFTCard
                    className={`w-full`}
                    key={`${asset.app_id}-${asset.name}`}
                    id={parseInt(asset.id)}
                    listingId={asset.app_id}
                    name={asset.name}
                    collection={asset.collection}
                    image={asset.image}
                    rank={asset.rank}
                    loanamount={asset.loanAmount}
                    isAppOwner={false}
                    price={asset.price && parseInt(asset.price)}
                    owner={asset.owner}
                    small
                    isOwner={asset.owner === activeAddress}
                    amount={asset.amount}

                    loanState="eligible"
                  />
                )
              })
            }

          </InfiniteScroll>
        </TabPanel>

        {/* Active Loans Panel */}
        <TabPanel className="rounded-xl bg-white/5 p-3" style={{ height: assetTabHeight && isSticky ? 'unset' : 'unset', overflowY: 'auto' }}>
          <div className="mb-4 flex gap-4">
            <select
              className="w-52 origin-top-right rounded-tab border border-white/5 bg-white/5 p-1 text-xs/6 text-white transition duration-100 ease-out [--anchor-gap:var(--spacing-1)] focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0 cursor-pointer"
              value={loanFilters.lendAmount}
              onChange={(e) => setLoanFilters(prev => ({ ...prev, lendAmount: e.target.value }))}
            >
              <option value="all">Loan Amount: All</option>
              {FILTERS.loans.lendAmount.ranges.slice(1).map(range => (
                <option key={`${range.min}-${range.max}`} value={`${range.min}-${range.max}`}>
                  {range.label}
                </option>
              ))}
            </select>

            <select
              className="w-52 origin-top-right rounded-tab border border-white/5 bg-white/5 p-1 text-xs/6 text-white transition duration-100 ease-out [--anchor-gap:var(--spacing-1)] focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0 cursor-pointer"
              value={loanFilters.lendTime}
              onChange={(e) => setLoanFilters(prev => ({ ...prev, lendTime: e.target.value }))}
            >
              <option value="all">Duration: All</option>
              {FILTERS.loans.lendTime.ranges.slice(1).map(range => (
                <option key={range.value} value={range.value}>
                  {range.label}
                </option>
              ))}
            </select>
          </div>
          <InfiniteScroll dataLength={categorizedLoans?.active.length || 0} hasMore={false} className="grid grid-cols-1 gap-4 sm:grid-cols-3 lg:grid-cols-4">
            {!loading && categorizedLoans?.active?.length == 0 && (
              <Nothing pt={0} t={0} position="relative" />
            )}
            {categorizedLoans && categorizedLoans?.active.map(loan => {
              return (
                <NFTCard
                  loanState="active"
                  key={`${loan.app_id}-${loan.name}`}
                  id={parseInt(loan.id)}
                  loanId={loan.app_id}
                  name={loan.name}
                  collection={loan.collection}
                  image={loan.image}
                  rank={loan.rank}
                  isAppOwner={loan.global_lend_address === activeAddress}
                  loanamount={getLendAmountG(loan)}
                  price={calcPriceG(loan)}
                  owner={loan.owner}
                  isOwner={loan.owner === activeAddress}
                  amount={loan.amount}

                  loanLength={getLendTimeG(loan)}
                  endDate={calcEndDateG(loan)}

                />
              )
            })}

          </InfiniteScroll>
        </TabPanel>

        {/* Overdue Loans Panel */}
        <TabPanel className="rounded-xl bg-white/5 p-3" style={{ height: assetTabHeight && isSticky ? 'unset' : 'unset', overflowY: 'auto' }}>
          <div className="mb-4 flex gap-4">
            <select
              className="w-52 origin-top-right rounded-tab border border-white/5 bg-white/5 p-1 text-xs/6 text-white transition duration-100 ease-out [--anchor-gap:var(--spacing-1)] focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0 cursor-pointer"
              value={loanFilters.lendAmount}
              onChange={(e) => setLoanFilters(prev => ({ ...prev, lendAmount: e.target.value }))}
            >
              <option value="all">Loan Amount: All</option>
              {FILTERS.loans.lendAmount.ranges.slice(1).map(range => (
                <option key={`${range.min}-${range.max}`} value={`${range.min}-${range.max}`}>
                  {range.label}
                </option>
              ))}
            </select>

            <select
              className="w-52 origin-top-right rounded-tab border border-white/5 bg-white/5 p-1 text-xs/6 text-white transition duration-100 ease-out [--anchor-gap:var(--spacing-1)] focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0 cursor-pointer"
              value={loanFilters.lendTime}
              onChange={(e) => setLoanFilters(prev => ({ ...prev, lendTime: e.target.value }))}
            >
              <option value="all">Duration: All</option>
              {FILTERS.loans.lendTime.ranges.slice(1).map(range => (
                <option key={range.value} value={range.value}>
                  {range.label}
                </option>
              ))}
            </select>
          </div>
          <InfiniteScroll dataLength={categorizedLoans?.overdue.length || 0} hasMore={false} className="grid grid-cols-1 gap-4 sm:grid-cols-3 lg:grid-cols-4">
            {!loading && categorizedLoans?.overdue?.length == 0 && (
              <Nothing pt={0} t={0} position="relative" />
            )}
            {categorizedLoans.overdue.map(loan => (
              <NFTCard
                loanState="overdue"
                key={`${loan.app_id}-${loan.name}`}
                id={parseInt(loan.id)}
                loanId={loan.app_id}
                name={loan.name}
                collection={loan.collection}
                image={loan.image}
                rank={loan.rank}
                loanamount={getLendAmountG(loan)}
                price={calcPriceG(loan)}
                owner={loan.owner}
                isAppOwner={loan.global_lend_address === activeAddress}
                isOwner={loan.owner === activeAddress}
                amount={loan.amount}

                loanLength={getLendTimeG(loan)}
                endDate={calcEndDateG(loan)}

              />
            ))}

          </InfiniteScroll>
        </TabPanel>

        {/* History Panel */}
        <TabPanel className="rounded-xl bg-white/5 p-3" style={{ height: assetTabHeight && isSticky ? 'unset' : 'unset', overflowY: 'auto' }}>
          <div className="mb-4 flex gap-4">
            <select
              className="w-52 origin-top-right rounded-tab border border-white/5 bg-white/5 p-1 text-xs/6 text-white transition duration-100 ease-out [--anchor-gap:var(--spacing-1)] focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0 cursor-pointer"
              value={loanFilters.lendAmount}
              onChange={(e) => setLoanFilters(prev => ({ ...prev, lendAmount: e.target.value }))}
            >
              <option value="all">Loan Amount: All</option>
              {FILTERS.loans.lendAmount.ranges.slice(1).map(range => (
                <option key={`${range.min}-${range.max}`} value={`${range.min}-${range.max}`}>
                  {range.label}
                </option>
              ))}
            </select>

            <select
              className="w-52 origin-top-right rounded-tab border border-white/5 bg-white/5 p-1 text-xs/6 text-white transition duration-100 ease-out [--anchor-gap:var(--spacing-1)] focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0 cursor-pointer"
              value={loanFilters.lendTime}
              onChange={(e) => setLoanFilters(prev => ({ ...prev, lendTime: e.target.value }))}
            >
              <option value="all">Duration: All</option>
              {FILTERS.loans.lendTime.ranges.slice(1).map(range => (
                <option key={range.value} value={range.value}>
                  {range.label}
                </option>
              ))}
            </select>
          </div>
          <InfiniteScroll dataLength={categorizedLoans?.history.length || 0} hasMore={false} className="grid grid-cols-1 gap-4 sm:grid-cols-3 lg:grid-cols-4">
            {!loading && categorizedLoans?.history?.length == 0 && (
              <Nothing pt={0} t={0} position="relative" />
            )}
            {categorizedLoans.history.map(loan => {
              return (
                <NFTCard
                  loanState="history"
                  key={`${loan.app_id}-${loan.name}`}
                  id={parseInt(loan.id)}
                  loanId={loan.app_id} name={loan.name}
                  collection={loan.collection}
                  image={loan.image}
                  rank={loan.rank}
                  loanamount={getLendAmountG(loan)}
                  price={getPaidG(loan)}
                  owner={loan.owner}
                  isAppOwner={loan.global_lend_address === activeAddress}
                  isOwner={loan.owner === activeAddress}
                  amount={loan.amount}

                  loanLength={getLendTimeG(loan)}
                  endDate={calcEndDateG(loan)}

                />
              );
            })}

          </InfiniteScroll>
        </TabPanel>
      </TabPanels>
    </TabGroup>
  )
}