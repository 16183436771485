import algosdk from 'algosdk';
import { getFreeLoans, lendNft, partialPay, payLoan, payOverdue } from '.';
import { base64Encode } from '../../helpers/base64';
const paymentAmountGroup = {
  ten: {
    index: 1,
    percentage: 0.1,
    text: '10%'
  },
  twentyFive: {
    index: 2,
    percentage: 0.25,
    text: '25%'
  },
  fifty: {
    index: 3,
    percentage: 0.5,
    text: '50%'
  },
  seventyFive: {
    index: 4,
    percentage: 0.75,
    text: '75%'
  },
  full: {
    index: 5,
    percentage: 1,
    text: '100%'
  },
}

export function showPayment(loanAmount, payAmountIndex) {
  switch (payAmountIndex) {
    case 1:
      return `${paymentAmountGroup.ten.text} = ${(loanAmount * paymentAmountGroup.ten.percentage).toFixed(6)} $ALGO`
    case 2:
      return `${paymentAmountGroup.twentyFive.text} = ${(loanAmount * paymentAmountGroup.twentyFive.percentage).toFixed(6)} $ALGO`
    case 3:
      return `${paymentAmountGroup.fifty.text} = ${(loanAmount * paymentAmountGroup.fifty.percentage).toFixed(6)} $ALGO`
    case 4:
      return `${paymentAmountGroup.seventyFive.text} = ${(loanAmount * paymentAmountGroup.seventyFive.percentage).toFixed(6)} $ALGO`
    case 5:
      return `${paymentAmountGroup.full.text} = ${(loanAmount * paymentAmountGroup.full.percentage).toFixed(6)} $ALGO`
    default:
      break;
  }
}
export function showPaymentAmount(loanAmount, payAmountIndex) {
  switch (payAmountIndex) {
    case 25:
      return `${(loanAmount * paymentAmountGroup.ten.percentage).toFixed(6)} $ALGO`
    case 25:
      return `${(loanAmount * paymentAmountGroup.twentyFive.percentage).toFixed(6)} $ALGO`
    case 50:
      return `${(loanAmount * paymentAmountGroup.fifty.percentage).toFixed(6)} $ALGO`
    case 75:
      return `${(loanAmount * paymentAmountGroup.seventyFive.percentage).toFixed(6)} $ALGO`
    case 100:
      return `${(loanAmount * paymentAmountGroup.full.percentage).toFixed(6)} $ALGO`
    default:
      return `0% = 0 ALGOS`
  }
}

export function getAmountToPay(payAmountIndex, loanAmount, isOverDue) {
  if (isOverDue) return loanAmount
  switch (payAmountIndex) {
    case 10:
      return loanAmount * paymentAmountGroup.ten.percentage;

    case 25:
      return loanAmount * paymentAmountGroup.twentyFive.percentage;

    case 50:
      return loanAmount * paymentAmountGroup.fifty.percentage;

    case 75:
      return loanAmount * paymentAmountGroup.seventyFive.percentage;

    case 100:
      return loanAmount;

    default:
      return loanAmount;
  }
}

export async function createPayTransaction(nftId, loanId, loanAmount, payAmountIndex, isOverDue, signProps) {
  let payAmount = getAmountToPay(payAmountIndex, loanAmount, isOverDue)

  const { algodClient } = signProps

  nftId = parseInt(nftId)
  loanId = parseInt(loanId)
  loanAmount = parseFloat(loanAmount)

  const params = await algodClient.getTransactionParams().do();
  if (isOverDue) {
    return overDuePayment(params, nftId, loanId, payAmount, signProps)
  }
  if (payAmount === loanAmount) {
    return await fullPayment(params, nftId, loanId, payAmount, signProps)
  } else {
    return await partialPayment(params, nftId, loanId, payAmount, signProps)
  }
}

async function fullPayment(params, nftId, loanId, payAmount, signProps) {
  try {
    let transactionsToSend
    const { address, signTransactions, setSignTxn, setDisabled, setLoading } = signProps

    const optInTransaction = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
      suggestedParams: {
        ...params,
      },
      from: address,
      to: address,
      assetIndex: nftId,
      amount: 0,
    });

    const appArgs = [
      new Uint8Array(Buffer.from('pay')),
    ];

    const payTransaction = algosdk.makeApplicationNoOpTxnFromObject({
      suggestedParams: {
        ...params,
      },
      from: address,
      appIndex: loanId,
      appArgs: appArgs,
      foreignAssets: [nftId],
    });

    payTransaction.fee = 3000;

    const fundTransaction = algosdk.makePaymentTxnWithSuggestedParamsFromObject({
      suggestedParams: {
        ...params,
      },
      from: address,
      to: algosdk.getApplicationAddress(loanId),
      amount: parseInt(algosdk.algosToMicroalgos(payAmount)),
    });

    const txnsToGroup = [optInTransaction, payTransaction, fundTransaction];

    const groupID = algosdk.computeGroupID(txnsToGroup);
    for (let i = 0; i < txnsToGroup.length; i++) txnsToGroup[i].group = groupID;

    const encodedGrp = txnsToGroup.map(t => algosdk.encodeUnsignedTransaction(t))

    setSignTxn(true)
    setDisabled(true)
    transactionsToSend = await signTransactions(encodedGrp);
    setDisabled(true)
    setSignTxn(false)

    const decodedTxn = transactionsToSend.map(t => base64Encode(t))
    if (decodedTxn) {
      setDisabled(true)
      setLoading(true);
      await payLoan(decodedTxn)
      setDisabled(false)
      setLoading(false);
    }
  } catch (error) {
    setDisabled(false)
    setLoading(false)
    setSignTxn(false)
  }
}

export async function overDuePayment(params, nftId, loanId, payAmount, signProps) {
  let transactionsToSend
  const { address, signTransactions, algodClient, setSignTxn, setDisabled, setLoading } = signProps
  try {

    const optInTransaction = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
      suggestedParams: {
        ...params,
      },
      from: address,
      to: address,
      assetIndex: nftId,
      amount: 0,
    });

    const appArgs = [
      new Uint8Array(Buffer.from('pay')),
    ];

    const payTransaction = algosdk.makeApplicationNoOpTxnFromObject({
      suggestedParams: {
        ...params,
      },
      from: address,
      appIndex: loanId,
      appArgs: appArgs,
      foreignAssets: [nftId],
    });

    payTransaction.fee = 4000;

    const fundTransaction = algosdk.makePaymentTxnWithSuggestedParamsFromObject({
      suggestedParams: {
        ...params,
      },
      from: address,
      to: algosdk.getApplicationAddress(loanId),
      amount: parseInt(algosdk.algosToMicroalgos(payAmount)),
    });

    const txnsToGroup = [optInTransaction, payTransaction, fundTransaction];

    const groupID = algosdk.computeGroupID(txnsToGroup);
    for (let i = 0; i < txnsToGroup.length; i++) txnsToGroup[i].group = groupID;

    const encodedGrp = txnsToGroup.map(t => algosdk.encodeUnsignedTransaction(t))

    setSignTxn(true)
    setDisabled(true)
    transactionsToSend = await signTransactions(encodedGrp);
    setSignTxn(false)
    const decodedTxn = transactionsToSend.map(t => base64Encode(t))
    if (decodedTxn) {
      setLoading(true);
      const response = await payOverdue(decodedTxn)
      setDisabled(false)
      setLoading(false);
      return response
    }
  } catch (error) {
    setDisabled(false)
    setLoading(false)
    setSignTxn(false)
    throw error
  }
}

async function partialPayment(params, nftId, loanId, payAmount, signProps) {
  let transactionsToSend
  const { address, signTransactions, showLoadingModal } = signProps


  const appArgs = [
    new Uint8Array(Buffer.from('partial_pay')),
  ];

  const payTransaction = algosdk.makeApplicationNoOpTxnFromObject({
    suggestedParams: {
      ...params,
    },
    from: address,
    appIndex: loanId,
    appArgs: appArgs,
    foreignAssets: [nftId],
  });

  payTransaction.fee = 2000;

  const fundTransaction = algosdk.makePaymentTxnWithSuggestedParamsFromObject({
    suggestedParams: {
      ...params,
    },
    from: address,
    to: algosdk.getApplicationAddress(loanId),
    amount: parseInt(algosdk.algosToMicroalgos(payAmount)),
  });

  const txnsToGroup = [payTransaction, fundTransaction];

  const groupID = algosdk.computeGroupID(txnsToGroup);
  for (let i = 0; i < txnsToGroup.length; i++) txnsToGroup[i].group = groupID;

  transactionsToSend = await signTransactions(
    txnsToGroup,
  );

  if (transactionsToSend) {
    showLoadingModal();
    return await partialPay(transactionsToSend)
  }
}

const sixtyDays = 5184000
const nintyDays = 7776000
const threeDays = 259200
const thirtyDays = 2592000
export function convertSecondsToDate(s) {
  switch (s) {
    case sixtyDays:

      return "60 Days"
    case nintyDays:

      return "90 Days"
    case thirtyDays:

      return "30 Days"
    default:
      return '3 Days'
  }
}

export function convertStateToView(s) {
  switch (s) {
    case 'debt_claimed':

      return 'PAID'
    default:
      return 'LENT'
  }
}


export function calculateLockTime(lastLoanTime) {
  // Define the lock period of 3 days in seconds
  const lockPeriod = 3 * 24 * 60 * 60;

  // Get the current time in Unix seconds
  const currentTime = Math.floor(Date.now() / 1000);

  // Calculate the next available time for loaning in Unix seconds
  const availableTime = lastLoanTime + lockPeriod;

  // If the current time is greater than or equal to the available time, it can be loaned again (return 0)
  if (currentTime >= availableTime) {
    return 0;
  } else {
    // Otherwise, return the Unix time of when it will be available again
    return availableTime;
  }
}


export function isWithinCooldownPeriod(loanTimestamp) {
  // Calculate the current timestamp
  const currentTimestamp = Math.floor(convertMsToS(Date.now()));
  // Calculate the time difference in seconds
  const timeDifference = currentTimestamp - loanTimestamp;
  // Calculate remaining time until another loan can be taken (in seconds)
  const remainingTimeSeconds = threeDays - timeDifference;
  // Convert remaining time to days, hours, minutes
  const waitTime = convertSToDMS(remainingTimeSeconds)
  return { withinCooldownPeriod: timeDifference < threeDays, ...waitTime }
}

export async function createLendModalTxn(nftId, loanLength, algodClient, activeAddress, signTransactions, toast, setSignTxn) {
  try {
    const appId = await getFreeLoans()
      .then((res) => parseInt(res))
      .catch((e) => {
        console.log("getFreeLoans ", e)
        toast.error('No Available Loans.');
      })
    if (!appId) return
    const params = await algodClient.getTransactionParams().do();

    const appArgs = [
      new Uint8Array(Buffer.from('lend')),
    ];

    const lendTransaction = algosdk.makeApplicationNoOpTxnFromObject({
      suggestedParams: {
        ...params,
      },
      from: activeAddress,
      appIndex: parseInt(appId),
      appArgs: appArgs,
      foreignAssets: [nftId],
    });

    lendTransaction.fee = 2000;

    const assetTransferTransaction = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
      suggestedParams: {
        ...params,
      },
      from: activeAddress,
      to: algosdk.getApplicationAddress(parseInt(appId)),
      assetIndex: nftId,
      amount: 1,
    });

    const txnsToGroup = [lendTransaction, assetTransferTransaction];

    const groupID = algosdk.computeGroupID(txnsToGroup);
    for (let i = 0; i < txnsToGroup.length; i++) txnsToGroup[i].group = groupID;

    const encodedGrp = txnsToGroup.map(t => algosdk.encodeUnsignedTransaction(t))
    const transactionsToSend = await signTransactions(encodedGrp);
    if (!transactionsToSend) {
      toast.error("Transaction Failed")
      throw Error("Missing Transactions")
    }
    setSignTxn(false)
    const decodedTxn = transactionsToSend.map(t => base64Encode(t))
    return await lendNft(
      loanLength,
      nftId,
      decodedTxn,
      appId,
    )
      .then(() => {
        toast.success('Loaned NFT Successfully!');
        return true
      })
      .catch((error) => {
        console.log("Failed: ", error.message)
        toast.error('NFT Loan Failed. Check Your Balance.');
        return false
      })

  } catch (error) {
    console.log("createLendTxn: ", error.message)
    toast.error("Transaction Was Cancelled")
    return false
  }
}

export function convertSToDMS(s) {
  return { days: Math.floor(convertSToDays(s)), hours: Math.floor(convertSToHours(s)), minutes: Math.floor(convertSToMinutes(s)) }
}

function convertMsToS(ms) {
  return ms / 1000
}

function convertSToDays(s) {
  return s / (24 * 3600)
}

function convertSToHours(s) {
  return (s % (24 * 3600)) / 3600
}

function convertSToMinutes(s) {
  return (s % 3600) / 60
}