import React, { useRef, useCallback, useEffect, useState } from 'react';

import { connect } from 'react-redux';

import PropTypes from 'prop-types';

import { SHOW_PAY_MODAL, SHOW_LOADING_MODAL } from 'Root/services/constants/ActionTypes';

import { openWalletConnectModal, openLoadingModal, closeModal } from 'Root/services/actions/actions';

import toast from 'react-hot-toast';

import AlgoImage from 'Root/images/algo.png';

import { useClientContext } from 'Context/clientProvider'

import useTheme from 'Hooks/useTheme';

import { useWallet } from '@txnlab/use-wallet';

import { Button, Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react'

import { CircleX } from 'lucide-react';

import { Card } from '../../Cards/statCard';
import CountdownTimer from '../../NFTCard/CountDown';
import { AlgoAmount } from '@algorandfoundation/algokit-utils/types/amount';
import LoanPercentageSelector from '../../NFTCard/LoanSelector';
import createPayTransaction from '../../../services/api/transactions/createPayTransaction';
import { useLoanQueries } from '../../../context/loanQueryProvider';
import { getStateMessage, TransactionStates } from '../../../services/api/transactions/utils';
import { useLocalWallet } from '../../../context/walletProvider';

// Custom hook for payment state management
const usePaymentState = (initialLoanAmount) => {
  const [state, setState] = useState({
    payAmountIndex: 100,
    loading: false,
    signTxn: false,
    disabled: false,
    state: '',
    totalRepayable: `${initialLoanAmount || 0} ALGO`
  });

  const updateState = (newState) => {
    setState(prev => ({ ...prev, ...newState }));
  };

  return [state, updateState];
};

const PayModal = ({ payModal, closeModal }) => {
  if (!payModal) return null;
  const { isGoannaHolder } = useLocalWallet()
  const { algodClient } = useClientContext();
  const { activeAddress, signTransactions } = useWallet();
  const { getIsDark } = useTheme();
  const { handleTransaction } = useLoanQueries()
  const [isOpen, setIsOpen] = useState(true);
  const modalRef = useRef();
  const [
    { payAmountIndex, loading, signTxn, disabled, totalRepayable },
    updateState
  ] = usePaymentState(payModal?.loanAmount);

  // Payment handling
  const handlePayment = async () => {
    const signProps = {
      address: activeAddress,
      signTransactions,
      algodClient,
      setSignTxn: (value) => updateState({ signTxn: value }),
      setDisabled: (value) => updateState({ disabled: value }),
      setLoading: (value) => updateState({ loading: value }),
      onStateChange: (newState) => {
        updateState({ state: getStateMessage(newState) });
        // Show appropriate loading messages
        switch (newState) {
          case TransactionStates.PREPARING:
            return toast.loading(getStateMessage(newState));
          case TransactionStates.AWAITING_SIGNATURE:
            return toast.loading(getStateMessage(newState));
          case TransactionStates.SENDING:
            return toast.loading(getStateMessage(newState));
          case TransactionStates.CONFIRMED:
            return toast.loading(getStateMessage(newState));
          default:
            return toast.loading(getStateMessage(newState));
        }
      }
    }
    const isOverDue = payModal?.isOverDue
    const isAppOwner = payModal?.isAppOwner
    if (isOverDue && !isAppOwner && !isGoannaHolder) return toast.error("You must hold an Al Goanna V1 NFT to pay overdue loans.")

    try {

      const result = await createPayTransaction(
        payModal.id,
        payModal.loanId,
        payModal.loanAmount,
        payAmountIndex,
        payModal.isOverDue,
        signProps
      );

      if (result.success) {
        toast.success("Payment successful");
      }

    } catch (error) {
      // Error will have context about which stage failed
      console.log("TOAST: ", error.message)
      toast.error("Payment failed");
    } finally {
      handleTransaction("PAY")
    }

  };

  // Modal handling
  const close = useCallback(() => {
    setIsOpen(false);
    closeModal(SHOW_PAY_MODAL);
  }, [closeModal]);

  useEffect(() => {
    const handleEscape = (e) => {
      if (e.key === 'Escape') close();
    };
    document.addEventListener('keydown', handleEscape);
    return () => document.removeEventListener('keydown', handleEscape);
  }, [close]);

  useEffect(() => {
    if (payModal?.type === SHOW_PAY_MODAL) {
      setIsOpen(true);
    }
  }, [payModal]);

  return (
    <div ref={modalRef}>
      <Dialog
        open={isOpen}
        className="relative z-50 focus:outline-none"
        onClose={close}
      >
        <DialogBackdrop className="fixed inset-0 bg-black/30 backdrop-blur" />
        <div className="fixed inset-0 z-50 w-screen overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-2 md:p-20">
            <DialogPanel
              transition
              className="w-full max-w-md rounded-xl bg-white/5 p-6 backdrop-blur-2xl duration-300 ease-out data-[closed]:transform-[scale(95%)] data-[closed]:opacity-0"
            >
              {/* Modal Header */}
              <DialogTitle as="div" className="flex text-dark-text flex-row justify-between items-center">
                <h6 className="flex flex-col" id="payModalLabel">
                  {payModal.name}
                </h6>
                <button
                  type="button"
                  className="w-6 h-6 flex items-center align-center justify-center"
                  onClick={close}
                >
                  <CircleX color={getIsDark() ? '#F114FC' : '#fccc2d'} />
                </button>
              </DialogTitle>

              {/* Modal Content */}
              <div className="relative">
                <div className="p-3 pt-0 text-gray-800">
                  <div className="flex flex-col gap-3 my-3 items-end">
                    {/* Image and Action Button */}
                    <div className="w-full flex flex-row justify-between align-start items-end pb-3">
                      <img
                        className={`w-1/2 rounded-big ${loading ? 'animate-pulse' : ''}`}
                        src={payModal.image}
                        alt="paying asset"
                      />
                      {signTxn ? (
                        <Button
                          className="inline-flex items-center gap-2 rounded-md bg-white/5 hover:bg-dark-gradient-hover dark:hover:bg-mutant-gradient-hover dark:bg-mutant-gradient py-1.5 px-3 text-sm/6 font-semibold text-white shadow-inner shadow-white/10 focus:outline-none data-[hover]:bg-gray-600 data-[focus]:outline-1 data-[focus]:outline-white data-[open]:bg-gray-700 animate-pulse"
                        >
                          Sign Transaction
                        </Button>
                      ) : (
                        <Button
                          disabled={disabled}
                          className="inline-flex items-center gap-2 rounded-md bg-dark-gradient hover:bg-dark-gradient-hover dark:hover:bg-mutant-gradient-hover dark:bg-mutant-gradient py-1.5 px-3 text-sm/6 font-semibold text-white shadow-inner shadow-white/10 focus:outline-none data-[hover]:bg-gray-600 data-[focus]:outline-1 data-[focus]:outline-white data-[open]:bg-gray-700 disabled:bg-gray-700"
                          onClick={() => { handlePayment() }}
                        >
                          {loading ? "Loading" : "Pay"}
                        </Button>
                      )}
                    </div>

                    {/* Payment Details Grid */}
                    <div className="w-full">
                      <div className="grid grid-cols-2 lg:grid-cols-2 gap-0 lg:gap-4 mb-4 text-center items-center w-full">
                        <Card
                          textSize="text-sm"
                          className="rounded-tl-lg bg-black/25 border-white/10"
                          loading={false}
                          title="Duration"
                        >
                          <CountdownTimer
                            deadlineUnix={payModal.endDate}
                            isHistory={false}
                            center={true}
                          />
                        </Card>
                        <Card
                          textSize="text-sm"
                          className="rounded-tr-lg bg-black/25 border-white/10"
                          loading={false}
                          title="Receive"
                          stat={`${payModal.loanamount} ALGO`}
                        >
                          <LoanPercentageSelector
                            loanAmount={payModal.loanAmount}
                            setTotalRepayable={(value) => updateState({ totalRepayable: value })}
                            percentage={payModal.isOverDue ? 100 : payAmountIndex}
                            setPercentage={(value) => updateState({ payAmountIndex: value })}
                          />
                        </Card>
                        <Card
                          textSize="text-sm"
                          className="rounded-bl-lg bg-black/25 border-white/10"
                          loading={false}
                          title="Fee"
                          stat={`${payModal.isOverDue ?
                            AlgoAmount.MicroAlgos(4000).algos :
                            AlgoAmount.MicroAlgos(3000).algos
                            } Algos`}
                        />
                        <Card
                          textSize="text-sm"
                          className="rounded-br-lg bg-black/25 border-white/10"
                          loading={false}
                          title="Total repayable:"
                          stat={totalRepayable}
                        />
                      </div>
                    </div>

                    <div className="w-full pt-3 text-center text-gray-500 text-sm">
                      Be sure to double-check your loan length.
                    </div>
                  </div>
                </div>
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => ({
  payModal: state.payModal,
  address: state.connect?.address,
});

const mapDispatchToProps = (dispatch) => ({
  showWalletConnectModal: () => dispatch(openWalletConnectModal()),
  showLoadingModal: () => dispatch(openLoadingModal()),
  closeModal: (id) => dispatch(closeModal(id)),
});

PayModal.propTypes = {
  address: PropTypes.string,
  payModal: PropTypes.object,
  showWalletConnectModal: PropTypes.func,
  showLoadingModal: PropTypes.func,
  closeModal: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(PayModal);
