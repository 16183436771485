/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Disclosure, DisclosureButton, Transition, DisclosurePanel } from '@headlessui/react';
import { ChevronDownIcon } from 'lucide-react';

const pStyle = `text-white my-4 text-sm`;

const FAQ = () => {
  document.title = 'FAQ';

  const faqItems = [
    {
      question: "How does this all work?",
      answer: "We give NFTs a value based on their current floor price and distribution. We offer a percentage of that floor price whilst holding your NFT as collateral. You pay a set APR for up to 30 days, and we return the NFT back to you once the loan has been paid in full."
    },
    {
      question: "Where do the funds come from?",
      answer: "All liquidity is supplied by the Goanna DAO."
    },
    {
      question: "Where does the interest go?",
      answer: "The interest goes exclusively to the Goanna DAO."
    },
    {
      question: "How long are the loans for?",
      answer: "Loans are for a set period of up to 30 days. You are able to repay loans at any time but will pay the interest calculated on the full loan term."
    },
    {
      question: "How much is the interest rate?",
      answer: "The APR is 60.83% with an effective interest rate of 5% for the 30 day period."
    },
    {
      question: "Can I take more than one loan?",
      answer: "Yes, you can take loans against any eligible NFTs you own."
    },
    {
      question: "What is the cooldown period?",
      answer: "After repaying a loan, the same NFT cannot be used to take another loan for 3 days. This is to allow everyone an equal chance to utilize the bank."
    },
    {
      question: "How do I earn $GOAN?",
      answer: "For taking a loan and successfully repaying it, the user will receive a reward of $GOAN if they are opted in. Please opt in prior to repaying your loan (ASA: 1387238831).",
      helpers: ["Repaying a 2k Algo loan against a V1 user receives: 2466 $GOAN", "Repaying a 50a loan against an MvZ user receives: 61.5 $GOAN"]
    },
    {
      question: "What happens if I don't repay in time?",
      answer: "You have the option to pay for an overdue loan by repaying the initial total principal + interest.",
      helpers: ["You have a 3-day grace period to repay the overdue loan.", "After the 3-day grace period, the Loan is transferred to the Pawn Shop to be Auctioned."]
    },
    {
      question: "Can I change the term of my loan?",
      answer: "Not at this time. But you can repay early at any time.",
    },
  ];

  return (
    <div className='p-4 rounded-big max-w-screen-lg m-auto bg-transparent z-0'>
      {/* Main content container with top padding for nav */}
      <div className='pt-4 px-4 max-w-screen-lg mx-auto'>
        {/* Sticky header - positioned below nav */}
        <div className="bg-dark-table/80 backdrop-blur-xl p-4 rounded-t-lg z-10 border-b border-white/10">
          <h1 className="text-lg text-dark-brand dark:text-mutant-brand text-center">FAQ</h1>
          <p className="text-white mt-2 text-sm">
            You can use your NFTs as collateral to take an Algo based loan.
            Your NFT is held in a smart contract and returned once the loan is repaid.
          </p>
        </div>

        {/* Content section */}
        <div className="mt-4 rounded-b-lg bg-black/10 backdrop-blur-2xl">
          {/* Loan Terms Grid */}
          <div className="p-4">
            <h2 className="text-dark-brand dark:text-mutant-brand font-medium mb-4">Available Loans</h2>
            <LoanTermsGrid />
          </div>

          {/* FAQ Dropdowns */}
          <div className="p-4 space-y-2">
            {faqItems.map((item, index) => (
              <Disclosure key={index}>
                {({ open }) => (
                  <div className="bg-black/20 rounded-lg">
                    <DisclosureButton className="w-full flex justify-between items-center px-4 py-3 text-left hover:bg-white/5 transition-colors">
                      <h4 className='text-dark-brand dark:text-mutant-brand font-medium'>{item.question}</h4>
                      <ChevronDownIcon
                        className={`${open ? 'transform rotate-180' : ''
                          } w-5 h-5 text-dark-brand dark:text-mutant-brand transition-transform duration-200`}
                      />
                    </DisclosureButton>
                    <Transition
                      enter="transition duration-100 ease-out"
                      enterFrom="transform scale-95 opacity-0"
                      enterTo="transform scale-100 opacity-100"
                      leave="transition duration-75 ease-out"
                      leaveFrom="transform scale-100 opacity-100"
                      leaveTo="transform scale-95 opacity-0"
                    >
                      <DisclosurePanel className="px-4 pb-4">
                        <p className={pStyle}>{item.answer}</p>
                        {item?.helpers && item.helpers.map(helper => (
                          <p className={pStyle}>{helper}</p>
                        ))}
                      </DisclosurePanel>
                    </Transition>
                  </div>
                )}
              </Disclosure>
            ))}
          </div>

          {/* Disclaimer */}
          <div className="p-4">
            <div className="bg-white/5 rounded-lg p-4">
              <h4 className='text-dark-brand dark:text-mutant-brand font-medium mb-2'>Disclaimer</h4>
              <p className={`${pStyle}`}>The Goanna lending platform is run by the Goanna DAO community.
                The Goanna NFT Project and Shufl Labs are not responsible for this platform.
                If you use this service, you are taking a risk.
                The Goanna DAO is not responsible for any losses you may experience while using this platform.
                You are responsible for paying back your loans on time.
                If you don't, you may lose your assets.
                The Goanna DAO suggests that users understand the risks of decentralized technology before
                using the platform.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const LoanDisclosure = ({ loan }) => (
  <Disclosure>
    {({ open }) => (
      <div className={`rounded-lg ${open ? 'bg-white/5' : 'bg-transparent'}`}>
        <DisclosureButton className="w-full flex justify-between items-center px-4 py-3 text-left hover:bg-white/5 transition-colors">
          <div className="flex items-center space-x-3">
            <h4 className='text-dark-brand dark:text-mutant-brand font-medium'>{loan.nftName}</h4>
            <span className="text-white/60 text-sm">{loan.loanAmount}A</span>
          </div>
          <ChevronDownIcon
            className={`${open ? 'transform rotate-180' : ''
              } w-5 h-5 text-dark-brand dark:text-mutant-brand transition-transform duration-200`}
          />
        </DisclosureButton>

        <Transition
          enter="transition duration-100 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
        >
          <DisclosurePanel className={`px-4 pb-4 `}>
            <div className="space-y-2 text-sm pt-2">
              <div className="flex justify-between">
                <span className="text-white/60">Duration:</span>
                <span className="text-white">Up to {loan.maxDays} days</span>
              </div>
              <div className="flex justify-between">
                <span className="text-white/60">Goann Cashback:</span>
                <span className="text-white">{loan.goanna} GOANNA</span>
              </div>
              <div className="flex justify-between">
                <span className="text-white/60">Effective Rate:</span>
                <span className="text-white">{loan.effectiveRate}%</span>
              </div>
              <div className="flex justify-between">
                <span className="text-white/60">Interest:</span>
                <span className="text-white">{loan.interestPayable}A</span>
              </div>
              <div className="flex justify-between font-medium">
                <span className="text-white/60">Total Repayable:</span>
                <span className="text-white">{loan.totalRepayable}A</span>
              </div>
            </div>
          </DisclosurePanel>
        </Transition>
      </div>
    )}
  </Disclosure>
);

const LoanTermsGrid = () => {
  const loans = [
    {
      nftName: "Al Goanna (v1)",
      maxDays: 30,
      loanAmount: 2000,
      apr: 60.83,
      effectiveRate: 5,
      interestPayable: 100,
      totalRepayable: 2100,
      goanna: 5000
    },
    {
      nftName: "Mutants vs. Zombies (v2)",
      maxDays: 30,
      loanAmount: 50,
      apr: 60.83,
      effectiveRate: 5,
      interestPayable: 2.5,
      totalRepayable: 52.5,
      goanna: 125
    },
    {
      nftName: "Stupid Horse",
      maxDays: 30,
      loanAmount: 275,
      apr: 60.83,
      effectiveRate: 5,
      interestPayable: 13.75,
      totalRepayable: 288.75,
      goanna: 0
    },
    {
      nftName: "MNGO",
      maxDays: 30,
      loanAmount: 175,
      apr: 60.83,
      effectiveRate: 5,
      interestPayable: 8.75,
      totalRepayable: 183.75,
      goanna: 0
    },
    {
      nftName: "Mostly Frens",
      maxDays: 30,
      loanAmount: 125,
      apr: 60.83,
      effectiveRate: 5,
      interestPayable: 6.25,
      totalRepayable: 131.25,
      goanna: 0
    },
    {
      nftName: "The Lost Pigs",
      maxDays: 30,
      loanAmount: 275,
      apr: 60.83,
      effectiveRate: 5,
      interestPayable: 13.75,
      totalRepayable: 288.75,
      goanna: 0
    },
    {
      nftName: "The Lost Bots",
      maxDays: 30,
      loanAmount: 75,
      apr: 60.83,
      effectiveRate: 5,
      interestPayable: 3.75,
      totalRepayable: 78.75,
      goanna: 0
    },
    {
      nftName: "Crazy Goose Flock",
      maxDays: 30,
      loanAmount: 40,
      apr: 60.83,
      effectiveRate: 5,
      interestPayable: 2,
      totalRepayable: 42,
      goanna: 0
    },
    // Add more loan objects here
  ];

  return (

    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 max-h-[calc(100vh-200px)] p-4">
      {loans.map((loan, index) => (
        <LoanDisclosure
          key={index}
          loan={loan}
        />
      ))}
    </div>

  );
};

export default FAQ;
