import { ShieldHalf } from 'lucide-react';
import React, { useState, useEffect } from 'react';

const nftLoanAmounts = [
  { name: "Al Goanna", amount: 2000 },
  { name: "Mutants Vs Zombies", amount: 50 },
  { name: "Stupid Horse", amount: 275 },
  { name: "Mostly Frens", amount: 125 },
  { name: "The Lost Bots", amount: 75 },
  { name: "MNGO", amount: 175 },
  { name: "The Lost Pigs", amount: 275 },
  { name: "Crazy Goose Flock", amount: 50 }
];

const MiniCard = ({ name, amount }) => (
  <div className="bg-white/5 rounded-md p-3 flex justify-between items-center border border-dark-brand2/50">
    <span className="text-white/90">{name}</span>
    <span className="text-white/80 font-mono">{amount}A</span>
  </div>
);

const NumberCard = ({ number, label }) => (
  <div className="flex flex-col items-center">
    <div className="relative mb-2">
      <div className="bg-white/10 backdrop-blur-lg rounded-2xl p-4 border border-white/20 shadow-lg">
        <div className="relative">
          <span className="text-5xl font-bold text-white tracking-tight min-w-[2ch] inline-block text-center">
            {number.toString().padStart(2, '0')}
          </span>
        </div>
      </div>
    </div>
    <span className="text-xs uppercase tracking-wider text-white/70 font-medium">
      {label}
    </span>
  </div>
);

const ComingSoon = () => {
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  });



  useEffect(() => {
    const calculateTimeLeft = () => {
      const now = new Date();
      const nextTuesday = new Date();
      const daysUntilTuesday = (2 - now.getDay() + 7) % 7;

      if (daysUntilTuesday === 0 && now.getHours() >= 23 && now.getMinutes() >= 59) {
        nextTuesday.setDate(now.getDate() + 7);
      } else {
        nextTuesday.setDate(now.getDate() + daysUntilTuesday);
      }

      nextTuesday.setHours(23, 59, 59, 999);
      const difference = nextTuesday.getTime() - now.getTime();

      if (difference > 0) {
        setTimeLeft({
          days: Math.floor(difference / (1000 * 60 * 60 * 24)),
          hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
          minutes: Math.floor((difference / 1000 / 60) % 60),
          seconds: Math.floor((difference / 1000) % 60)
        });
      }
    };

    calculateTimeLeft();
    const timer = setInterval(calculateTimeLeft, 1000);
    return () => clearInterval(timer);
  }, []);

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 to-gray-800 flex justify-center py-8 z-50">
      <div className="w-full max-w-screen-lg flex flex-col items-center gap-8 p-4">
        <h1 className="text-4xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-dark-brand to-mutant-brand text-center">
          PAWN SHOP COMING SOON
        </h1>

        <div className="flex flex-wrap justify-center gap-4 p-8">
          <NumberCard number={timeLeft.days} label="Days" />
          <NumberCard number={timeLeft.hours} label="Hours" />
          <NumberCard number={timeLeft.minutes} label="Minutes" />
          <NumberCard number={timeLeft.seconds} label="Seconds" />
        </div>

        <PawnShopDescription />
      </div>
    </div>
  );
};
export const PawnShopDescription = () => {
  return (
    <div className="max-w-2xl space-y-6 text-white/80">
      <div className="bg-dark-table/50 backdrop-blur-sm rounded-xl p-6 space-y-4">
        <h2 className="text-xl font-semibold text-white">Feature Rollout</h2>
        <p className="leading-relaxed">
          <strong>Goanna Bank is proud to unveil our latest feature.</strong>
          <br />
          The Pawn Shop opens a new chapter in our ecosystem, where Goanna holders can now claim overdue loans. If a loan becomes overdue, the owner has a 3-day grace period to reclaim their NFT by paying the full loan amount. After this period, the NFT enters the Pawn Shop and becomes available on a first-come, first-served basis.
        </p>
      </div>

      <div className="bg-dark-table/50 backdrop-blur-sm rounded-xl p-6 space-y-4">
        <h2 className="text-xl font-semibold text-white">Available Loan Amounts</h2>
        <div className="grid gap-2 sm:grid-cols-2">
          {nftLoanAmounts.map((nft) => (
            <MiniCard key={nft.name} name={nft.name} amount={nft.amount} />
          ))}
        </div>
      </div>
      <NotebookList />

    </div>
  )
}
const NotebookList = () => (
  <div className="bg-dark-table/50 backdrop-blur-sm rounded-xl p-6">
    <h2 className="text-xl font-semibold text-white mb-4">HOW IT WORKS!</h2>

    {/* Pokemon-style dialogue box */}
    <div className="relative">
      {/* Main box with thick border */}
      <div className="rounded-lg p-6 relative">
        {/* Inner black border */}
        <div className="absolute inset-0 border-2 border-black rounded-lg m-1" />

        {/* Content with Pokemon-style arrows */}
        <div className="space-y-6 relative text-black">
          <Shield text={"OVERDUE LOANS REQUIRE FULL PAYMENT! PREVIOUS PAYMENTS WON'T COUNT!"} />
          <Shield text={"OVERDUE LOAN HOLDERS GET A 3-DAY GRACE PERIOD TO RECLAIM THEIR NFT!"} />
          <Shield text={"AFTER THAT, THE NFT ENTERS... THE PAWN SHOP!"} />
          <Shield text={"FIRST TRAINER TO PAY THE FULL AMOUNT CATCHES THE NFT!"} />
        </div>
      </div>

      {/* Bottom arrow indicator */}
      <div className="absolute bottom-4 right-4 animate-bounce">
        <div className="w-0 h-0 border-l-[8px] border-l-transparent border-t-[12px] border-t-black border-r-[8px] border-r-transparent" />
      </div>
    </div>
  </div>
);
function Shield({ text }) {
  return <div className="flex items-center">
    <span className="text-2xl mr-2 text-dark-brand2 dark:text-mutant-brand">
      <ShieldHalf />
    </span>
    <p className="text-dark-brand text-sm">
      {text}
    </p>
  </div>
}
export default ComingSoon;