import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { getAssetDataFromPera } from "../helpers/utils/getters";

const getOptimizedImageUrl = (imageUrl) => {
  if (!imageUrl || imageUrl === 'false' || imageUrl.startsWith('data:image/svg')) {
    return imageUrl;
  }

  try {
    const urlImage = new URL(imageUrl);
    const innerWidth = window.innerWidth;
    const ratio = innerWidth <= 640 ? 1
      : innerWidth <= 768 ? 2
        : innerWidth <= 1024 ? 3
          : 4;
    const width = Math.floor(innerWidth / ratio);

    urlImage.searchParams.append('optimizer', 'image');
    urlImage.searchParams.append('width', width);

    return urlImage.href;
  } catch (error) {
    console.error('Error optimizing image URL:', error);
    return imageUrl;
  }
};

export default function useOptimizeImageHook(imageUrl, options = {}) {

  const breakpoint = useMemo(() => {
    const width = window.innerWidth;
    if (width <= 640) return 'xs';
    if (width <= 768) return 'sm';
    if (width <= 1024) return 'md';
    return 'lg';
  }, []);

  return useQuery({
    queryKey: ['image', imageUrl, breakpoint],
    queryFn: async () => {
      if (!imageUrl || imageUrl === 'false') {
        return null;
      }

      if (imageUrl.startsWith('data:image/svg')) {
        return imageUrl;
      }

      const optimizedUrl = getOptimizedImageUrl(imageUrl);

      return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => resolve(optimizedUrl);
        img.onerror = () => reject(new Error('Failed to load image'));
        img.src = optimizedUrl;
      });
    },
    gcTime: "Infinity",
    enabled: !!imageUrl && imageUrl !== 'false',
    staleTime: "Infinity",
    cacheTime: 1000 * 60 * 60, // 1 hour
    ...options,
  });
}

export function useOptimizedNftImage(nftID) {
  const { data } = useQuery({
    queryKey: ['nft-data', nftID],
    queryFn: () => getAssetDataFromPera(nftID),
    select: (data) => {
      const name = data?.name
      const thumbnail = data?.collectible?.thumbnail_url

      if (thumbnail) return { thumbnail, name }
      const image = data?.media[0]?.url
      if (image) return { thumbnail: image, name }
      return undefined
    },
    enabled: !!nftID,
    staleTime: "Infinity",
    cacheTime: 1000 * 60 * 60, // 1 hour
  });
  const imageUrl = data?.thumbnail
  const nftName = data?.name

  const size = 48; // Max size 48x48px

  const { data: url, isLoading } = useOptimizeImageHook(imageUrl, {
    queryKey: ['nft-image', nftID, size],
    select: (url) => {
      if (!url || url === 'false' || url.startsWith('data:image/svg')) {
        return url;
      }

      try {
        const urlImage = new URL(url);
        urlImage.searchParams.append('optimizer', 'image');
        urlImage.searchParams.append('width', size);
        urlImage.searchParams.append('height', size);
        return urlImage.href;
      } catch (error) {
        console.error('Error optimizing NFT image URL:', error);
        return url;
      }
    }

  });

  return { url, nftName, isLoading }
}
