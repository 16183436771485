import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react'
import { calcEndDateG, getLendAmountG, getLendTimeG, getPaybackG } from '../../helpers';
import InfiniteScroll from 'react-infinite-scroll-component';
import NFTCard from './NFTCard';
import { useState } from 'react';
import useIsSticky from 'Hooks/useIsSticky';
import { useWallet } from '@txnlab/use-wallet';
import Nothing from '../Sections/NotFoundSection/Nothing';
import { NFTCardSkeleton } from '../Skeletons/nftCardSkeleton';
import NFTSkeletonLoader from '../Skeletons/tabSkeleton';
import PawnShopDialog from '../Dialog/pawnShopDialog';

export default function PawnTabGrid({
  filteredAssets,
  assetFilters,
  setAssetFilters,
  loading,
  setLoanFilters,
  loanFilters,
  tabs,
  tabPanels,
}) {
  const { assetTabHeight, isSticky, tabHeight } = useIsSticky()
  const [selectedIndex, setSelectedIndex] = useState(0)
  const { activeAddress } = useWallet()

  if (loading && tabPanels?.length == 0) {
    return (
      <>
        <PawnShopDialog />
        <NFTSkeletonLoader />
      </>
    )
  }
  if (!loading && tabPanels?.length == 0) {
    return (
      <div className='w-full h-full flex flex-col justify-center align-center'>
        <PawnShopDialog />
        <h1 className='text-4xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-dark-brand to-mutant-brand text-center text-center mb-4 font-extrabold'>No Loot Found!</h1>
        <Nothing pt={0} t={0} position="relative" />
      </div>
    )
  }
  return (
    <>
      <PawnShopDialog />
      <TabGroup defaultIndex={2} selectedIndex={selectedIndex} onChange={setSelectedIndex} className={"w-full max-w-screen-lg px-4"} style={{ height: tabHeight && isSticky ? 'unset' : 'unset', overflowY: 'hidden' }}>
        <TabList id="Asset_Tab" className="hidden sm:flex flex-col sm:flex-row gap-4">
          {tabs && tabs.map(t => (
            <Tab className="rounded-md lg:rounded-full py-1 px-3 text-sm/6 font-semibold text-white focus:outline-none data-[selected]:bg-white/10 data-[hover]:bg-white/5 data-[selected]:data-[hover]:bg-white/10 data-[focus]:outline-1 data-[focus]:outline-white">
              {t.name} ({t.length})
            </Tab>
          ))}
        </TabList>
        <TabPanels className="mt-3">
          {/* Overdue Loans Panel */}
          {
            tabPanels && tabPanels.map(t => (
              <TabPanel className="rounded-xl bg-white/5 p-3" style={{ overflowY: 'auto' }}>
                <InfiniteScroll dataLength={t.length || 0} hasMore={false} className="grid grid-cols-1 gap-4 sm:grid-cols-3 lg:grid-cols-4">
                  {!loading && tabPanels?.length == 0 && (
                    <Nothing pt={0} t={0} position="relative" />
                  )}
                  {
                    loading && tabPanels?.length == 0 && Array.from({ length: 3 }, (_, index) => (
                      <NFTCardSkeleton key={index} />
                    ))
                  }
                  {t.map(loan => (
                    <NFTCard
                      loanState="overdue"
                      key={`${loan.app_id}-${loan.name}`}
                      id={parseInt(loan.id)}
                      loanId={loan.app_id}
                      name={loan.name}
                      collection={loan.collection}
                      image={loan.image}
                      rank={loan.rank}
                      loanamount={getLendAmountG(loan)}
                      price={getPaybackG(loan)}
                      owner={loan.owner}
                      isAppOwner={loan.global_lend_address === activeAddress}
                      isOwner={loan.owner === activeAddress}
                      amount={loan.amount}

                      loanLength={getLendTimeG(loan)}
                      endDate={calcEndDateG(loan)}

                    />
                  ))}

                </InfiniteScroll>
              </TabPanel>
            ))
          }
        </TabPanels>
      </TabGroup>
    </>
  )
}
