import React, { useEffect, useState, useMemo } from 'react';

import { connect } from 'react-redux';

import PropTypes from 'prop-types';

import { openLoadingModal, closeModal, openConnectModal } from 'Root/services/actions/actions';

import { FILTERS } from '../../../helpers';

import NftTabGrid from '../../NFTCard/NftTabGrid';

import { useLocalWallet } from '../../../context/walletProvider';
import { useNavigate } from 'react-router-dom';

function WalletSection({ showConnectModal }) {

  document.title = 'My Account';

  const navigate = useNavigate();

  const [assetFilters, setAssetFilters] = useState({
    loanLength: 'all',
    loanAmount: 'all',
    apr: 'all'
  });

  const [loanFilters, setLoanFilters] = useState({
    lendAmount: 'all',
    lendTime: 'all'
  });

  const [loanHistory, setLoanHistory] = useState([]);

  const { activeAddress } = useLocalWallet()

  const [wasConnected, setWasConnected] = useState(false);

  const [eligibleAssets, setEligibleAssets] = useState([]);
  const {
    eligibleAssetsData,
    isFetchingEAssets,
    eligibleAssetError,
    loanHistoryData,
    isFetchingLoanHistory,
    loanHistoryError
  } = useLocalWallet()

  // Utility function to check if a loan is active
  const isLoanActive = (loan) => {
    const status = loan.global_lend_status;
    const lendDate = loan.global_lend_date;
    const lendTime = loan.global_lend_time;
    if (status === 'paid' || status === 'debt_claimed') return false;

    const expiryTime = (lendDate + lendTime) * 1000;
    return Date.now() < expiryTime;
  };

  // Utility function to check if a loan is overdue
  const isLoanOverdue = (loan) => {
    const status = loan.global_lend_status;
    const lendDate = loan.global_lend_date;
    const lendTime = loan.global_lend_time;

    if (status === 'paid' || status === 'debt_claimed') return false;

    const expiryTime = (lendDate + lendTime) * 1000;
    return Date.now() >= expiryTime;
  };

  // Filtered and categorized loans
  const categorizedLoans = useMemo(() => {
    const filterLoan = (loan) => {
      const lendAmount = loan.global_lend_amount / 1e6;
      const lendTime = loan.global_lend_time;
      if (loanFilters.lendAmount !== 'all') {
        const range = FILTERS.loans.lendAmount.ranges.find(r => r.value === loanFilters.lendAmount);
        if (lendAmount < range.min || lendAmount > range.max) return false;
      }

      if (loanFilters.lendTime !== 'all' && lendTime !== loanFilters.lendTime) {
        return false;
      }

      return true;
    };

    return {
      active: loanHistory && loanHistory?.filter(loan => isLoanActive(loan) && filterLoan(loan)) || [],
      overdue: loanHistory && loanHistory?.filter(loan => isLoanOverdue(loan) && filterLoan(loan)) || [],
      history: loanHistory && loanHistory?.filter(loan => {
        const status = loan.global_lend_status;
        return (status === 'paid' || status === 'debt_claimed') && filterLoan(loan);
      }) || []
    };
  }, [loanHistory, loanFilters]);

  // Filtered loan assets
  const filteredAssets = useMemo(() => {
    return eligibleAssets?.filter(asset => {
      if (assetFilters.loanLength !== 'all' && asset.loanlength !== assetFilters.loanLength) {
        return false;
      }

      if (assetFilters.loanAmount !== 'all') {
        const range = FILTERS.assets.loanAmount.ranges.find(r => r.label === assetFilters.loanAmount);
        if (asset.loanamount < range.min || asset.loanamount > range.max) return false;
      }

      if (assetFilters.apr !== 'all') {
        const aprValue = parseInt(asset.loanapr);
        const range = FILTERS.assets.apr.ranges.find(r => r.label === assetFilters.apr);
        if (aprValue < range.min || aprValue > range.max) return false;
      }

      return true;
    }) || []
  }, [eligibleAssets, assetFilters]);

  useEffect(() => {
    if (eligibleAssetsData) {

      setEligibleAssets(eligibleAssetsData?.eligibleNFTs || [])
    }
  }, [eligibleAssetsData, isFetchingEAssets, eligibleAssetError])

  useEffect(() => {
    if (loanHistoryData?.history) {

      setLoanHistory(loanHistoryData.history || [])
    }
  }, [loanHistoryData, isFetchingLoanHistory, loanHistoryError])

  useEffect(() => {
    if (!activeAddress && !wasConnected) {
      showConnectModal()
    }
    if (!activeAddress && wasConnected) {
      navigate('/analytics');
    }

    // Track if wallet was ever connected
    if (activeAddress) {
      setWasConnected(true);
    }
  }, [activeAddress, wasConnected])

  return (
    <NftTabGrid
      filteredAssets={filteredAssets}
      categorizedLoans={categorizedLoans}
      assetFilters={assetFilters}
      setAssetFilters={setAssetFilters}
      loading={isFetchingEAssets && !eligibleAssetsData}
      setLoanFilters={setLoanFilters}
      loanFilters={loanFilters}
    />

  );
}

const mapStateToProps = (state) => ({
  address: state.connect?.address,
});

const mapDispatchToProps = (dispatch) => ({
  showLoadingModal: () => dispatch(openLoadingModal()),
  closeModal: (id) => dispatch(closeModal(id)),
  showConnectModal: () => dispatch(openConnectModal()),
});

WalletSection.propTypes = {
  address: PropTypes.string,
  closeModal: PropTypes.func,
  showLoadingModal: PropTypes.func,
  showConnectModal: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(WalletSection);
