import React, { useCallback } from 'react';

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  Outlet
} from 'react-router-dom';

import './App.scss';

import Welcome from 'Pages/welcome';

import Bank from 'Pages/bank';

import Wallet from 'Pages/wallet';

import NotFound from './pages/not-found';

import Faq from './pages/faq';

import Terms from './pages/terms';

import Privacy from './pages/privacy';

import ConnectModal from 'Components/Modals/ConnectModal/ConnectModal';

import WalletConnectModal from 'Components/Modals/WalletConnectModal/WalletConnectModal';

import LoadingModal from 'Components/Modals/LoadingModal/LoadingModal';

import LendModal from 'Components/Modals/LendModal/LendModal';

import PayModal from 'Components/Modals/PayModal/PayModal';

import ClientProvider from 'Context/clientProvider';

import LoanAnalytics from './pages/analytics';

import { WalletProvider } from './context/walletProvider';

import { useWallet } from '@txnlab/use-wallet';
import { LoanQueryProvider } from './context/loanQueryProvider';
import PawnShop from './pages/pawnshop';
import LoanProvider from './context/loanAnalyticsProvider';
import ComingSoon from './pages/comingSoon';
import { deadline } from './context/credentialsProvider';
import Layout from './components/Layout/layout';


/**
 * App.
 * @return {React.Component}
 */
export default function App() {
  const { activeAddress } = useWallet();

  // Set the deadline for next Tuesday
  const currentDate = new Date();
  const isBeforeDeadline = currentDate < deadline;

  const appHeight = () => {
    const doc = document.documentElement;
    doc.style.setProperty('--app-height', `${window.innerHeight}px`);
  };
  window.addEventListener('resize', appHeight);
  appHeight();

  const ProtectedRoute = useCallback(() => {
    return activeAddress ? <Outlet /> : <Navigate to="/analytics" replace />;
  }, [activeAddress])

  return (
    <ClientProvider>
      <LoanProvider>
        <LoanQueryProvider>
          <WalletProvider>
            <LoadingModal />
            <Layout>
              <Routes>
                <Route exact path='/' element={<Welcome />} />
                <Route exact path='/bank' element={<Bank />} />
                <Route exact path='/faq' element={<Faq />} />
                <Route exact path='/terms' element={<Terms />} />
                <Route exact path='/privacy' element={<Privacy />} />
                <Route exact path='/analytics' element={<LoanAnalytics />} />
                {isBeforeDeadline ? (
                  <Route exact path='/pawnshop' element={<ComingSoon />} />
                ) : (
                  <Route exact path='/pawnshop' element={<PawnShop />} />
                )}
                <Route exact path='/account' element={<Wallet />} />
                {/* <Route element={<ProtectedRoute />}>
                </Route> */}
                <Route path='*' element={<NotFound />} />
              </Routes>
            </Layout>
            <ConnectModal />
            <WalletConnectModal />
            <LendModal />
            <PayModal />
          </WalletProvider>
        </LoanQueryProvider>
      </LoanProvider>
    </ClientProvider>
  );
}