import React, { useRef, useCallback, useEffect, useState, act } from 'react';

import { connect } from 'react-redux';

import PropTypes from 'prop-types';

import { SHOW_LEND_MODAL } from 'Root/services/constants/ActionTypes';

import { openWalletConnectModal, openLoadingModal, closeModal } from 'Root/services/actions/actions';

import toast from 'react-hot-toast';

import AlgoImage from 'Root/images/algo.png';

import { useClientContext } from 'Context/clientProvider'

import { lastLoan } from 'Apis/index';

import { calculateLockTime, isWithinCooldownPeriod } from 'Apis//transactions';

import { Button, Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react'

import { CircleX } from 'lucide-react';

import useTheme from 'Hooks/useTheme';

import { Card } from '../../Cards/statCard';

import { useWallet } from '@txnlab/use-wallet';
import CountdownTimer from '../../NFTCard/CountDown';
import { getLoanByNftID } from '../../../services/api/graphql';
import { useLoanQueries } from '../../../context/loanQueryProvider';
import { createLendModalTxn } from '../../../services/api/transactions/createLendModal';
import { getStateMessage, TransactionStates } from '../../../services/api/transactions/utils';
import { getTotalRepayment } from '../../../helpers/utils/interest';

// Custom hook for loan state management
const useLoanState = (nftId, activeAddress) => {
  const [state, setState] = useState({
    loading: false,
    hasActiveLoan: false,
    hasActiveLoanMsg: '',
    signTxn: false,
    disabled: false,
    lockedTime: 0,
    state: '',
  });

  const updateState = (newState) => {
    setState(prev => ({ ...prev, ...newState }));
  };

  useEffect(() => {
    if (!nftId) return;

    async function fetchActiveLoan() {
      try {
        updateState({ loading: true });
        const lastLoanTimestamp = await getLoanByNftID(parseInt(nftId));
        if (lastLoanTimestamp) {
          const tp = parseFloat(lastLoanTimestamp);
          const locked = calculateLockTime(tp);

          const { days, hours, minutes, withinCooldownPeriod } = isWithinCooldownPeriod(lastLoanTimestamp);

          updateState({
            lockedTime: locked,
            hasActiveLoan: withinCooldownPeriod,
            hasActiveLoanMsg: withinCooldownPeriod
              ? `${days} days ${hours} hours ${minutes} minutes until NFT Cooldown is complete`
              : ''
          });
        }
      } catch (error) {
        console.error('Error fetching last loan:', error);
        updateState({
          hasActiveLoan: true,
          hasActiveLoanMsg: 'Error fetching last loan'
        });
      } finally {
        updateState({ loading: false });
      }
    }

    fetchActiveLoan();
  }, [nftId, activeAddress]);
  return [state, updateState];
};

const LendModal = ({
  lendModal,
  closeModal,
}) => {
  if (!lendModal) return null;

  const { algodClient } = useClientContext();
  const { activeAddress, signTransactions } = useWallet();
  const { getIsDark } = useTheme();

  const [isOpen, setIsOpen] = useState(true);
  const [timeFrame] = useState(2);
  const { handleTransaction } = useLoanQueries()
  const [state, updateState] = useLoanState(lendModal?.id, activeAddress);
  const {
    loading,
    hasActiveLoan,
    hasActiveLoanMsg,
    signTxn,
    disabled,
    lockedTime
  } = state;

  // Loan handling
  const handleLend = async (nftId, loanLengthIndex) => {
    const signProps = {
      algodClient,
      activeAddress,
      signTransactions,
      algodClient,
      setSignTxn: (value) => updateState({ signTxn: value }),
      setDisabled: (value) => updateState({ disabled: value }),
      setLoading: (value) => updateState({ loading: value }),
      onStateChange: (newState) => {
        updateState({ state: getStateMessage(newState) });
        // Show appropriate loading messages
        switch (newState) {
          case TransactionStates.PREPARING:
            return toast.loading(getStateMessage(newState));
          case TransactionStates.AWAITING_SIGNATURE:
            return toast.loading(getStateMessage(newState));
          case TransactionStates.SENDING:
            return toast.loading(getStateMessage(newState));
          case TransactionStates.CONFIRMED:
            return toast.loading(getStateMessage(newState));
          default:
            return toast.loading(getStateMessage(newState));
        }
      }
    }
    if (hasActiveLoan) {
      toast.error('You already took a loan for this NFT in the last 3 days.');
      return;
    }

    // const loanLength = loanLengthIndex === 1 ? 5184000 : 7776000;
    const loanLength = 2592000;

    try {
      const result = await createLendModalTxn(
        parseInt(nftId),
        loanLength,

        signProps
      );

      if (result.success) {
        toast.success(result.message);
      }
    } catch (error) {
      handleLendError(error);
    } finally {
      updateState({ loading: false, signTxn: false });
      handleTransaction('LEND')
    }
  };

  const handleLendError = (error) => {
    const errorMessages = {
      'NO_LOANS': 'No available loans.',
      'INSUFFICIENT_BALANCE': 'NFT Loan Failed. Check your balance.',
      'SIGNING_FAILED': 'Transaction was cancelled',
      'DEFAULT': 'Transaction failed. Please try again.'
    };

    toast.error(errorMessages[error.code] || errorMessages.DEFAULT);
    console.error('Lend transaction error:', error);
  };

  // Modal handling
  const close = useCallback(() => {
    setIsOpen(false);
    closeModal(SHOW_LEND_MODAL);
  }, [closeModal]);

  useEffect(() => {
    const handleEscape = (e) => {
      if (e.key === 'Escape') close();
    };

    document.addEventListener('keydown', handleEscape);
    return () => document.removeEventListener('keydown', handleEscape);
  }, [close]);

  useEffect(() => {
    if (hasActiveLoanMsg) {
      toast.error(hasActiveLoanMsg);
      updateState({ disabled: true });
    } else {
      updateState({ disabled: false });
    }
  }, [hasActiveLoanMsg]);

  return (
    <Dialog open={isOpen} className="relative z-50 focus:outline-none" onClose={close}>
      <DialogBackdrop className="fixed inset-0 bg-black/30 backdrop-blur" />
      <div className="fixed inset-0 z-50 w-screen overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-2 md:p-20">
          <DialogPanel className="w-full max-w-md rounded-xl bg-white/5 p-6 backdrop-blur-2xl">
            {/* Modal Header */}
            <DialogTitle className="flex text-dark-text flex-row justify-between items-center">
              <h6 className="flex flex-col">{lendModal.name}</h6>
              <button
                className="w-6 h-6 flex items-center align-center justify-center"
                onClick={close}
              >
                <CircleX color={getIsDark() ? '#F114FC' : '#fccc2d'} />
              </button>
            </DialogTitle>

            {/* Modal Content */}
            <div className="p-3 pt-0 text-gray-800">
              <div className="flex flex-col gap-3 my-3 items-end">
                {/* Image and Action Button */}
                <div className="w-full flex flex-row justify-between align-start items-end pb-3">
                  <img
                    className={`w-1/2 rounded-big ${loading ? 'animate-pulse' : ''}`}
                    src={lendModal.image}
                    alt="Lending asset"
                  />
                  <LendButton
                    signTxn={signTxn}
                    disabled={disabled || hasActiveLoan}
                    loading={loading}
                    hasActiveLoan={hasActiveLoan}
                    onClick={() => handleLend(lendModal.id, timeFrame)}
                  />
                </div>

                {/* Loan Details Cards */}
                <LoanDetailsGrid
                  lockedTime={lockedTime}
                  timeFrame={timeFrame}
                  loanAmount={lendModal.loanamount}
                  loading={loading}
                />

                <div className="w-full pt-3 text-center text-gray-500 text-sm">
                  Be sure to double-check your loan length.
                </div>
              </div>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
};
const LendButton = ({ signTxn, disabled, loading, hasActiveLoan, onClick }) => {
  if (signTxn) {
    return (
      <Button className="inline-flex items-center gap-2 rounded-md bg-white/5 hover:bg-dark-gradient-hover dark:hover:bg-mutant-gradient-hover dark:bg-mutant-gradient py-1.5 px-3 text-sm/6 font-semibold text-white shadow-inner shadow-white/10 focus:outline-none data-[hover]:bg-gray-600 data-[focus]:outline-1 data-[focus]:outline-white data-[open]:bg-gray-700 animate-pulse">
        Sign Transaction
      </Button>
    );
  }

  return (
    <button
      disabled={disabled || hasActiveLoan}
      className={`inline-flex items-center gap-2 rounded-md ${disabled || hasActiveLoan ? 'bg-gray-700' : 'data-[hover]:bg-gray-600 data-[focus]:outline-1 data-[focus]:outline-white data-[open]:bg-gray-700 bg-dark-gradient hover:bg-dark-gradient-hover dark:hover:bg-mutant-gradient-hover dark:bg-mutant-gradient'} py-1.5 px-3 text-sm/6 font-semibold text-white shadow-inner shadow-white/10 focus:outline-none
      }`}
      onClick={() => { onClick() }}
    >
      {loading ? "Loading" : hasActiveLoan ? "Locked" : "Lend"}
    </button>
  );
};
const LoanDetailsGrid = ({ lockedTime, timeFrame, loanAmount, loading }) => (
  <div className="grid grid-cols-2 gap-0 lg:gap-4 mb-4 text-center items-center w-full">
    {lockedTime > 0 ? (
      <Card textSize='text-sm' className="rounded-tl-lg" loading={false} title="Duration">
        <CountdownTimer deadlineUnix={lockedTime} isHistory={false} center={true} />
      </Card>
    ) : (
      <Card textSize='text-sm' className="rounded-tl-lg" loading={false} title="Duration" stat="30 Days" />
    )}
    <Card textSize='text-sm' className="rounded-tr-lg" loading={false} title="Receive" stat={`${loanAmount} ALGO`} />
    <Card textSize='text-sm' className="rounded-bl-lg" loading={false} title="Fee" stat="0.002 ALGO" />
    <Card
      textSize='text-sm'
      className="rounded-br-lg"
      loading={false}
      title="Total repayable:"
      stat={`${getTotalRepayment(loanAmount).repay.toFixed(2)} $ALGO`}
    />
  </div>
);
const mapStateToProps = (state) => ({
  lendModal: state.lendModal,
  address: state.connect?.address,
});

const mapDispatchToProps = (dispatch) => ({
  showWalletConnectModal: () => dispatch(openWalletConnectModal()),
  showLoadingModal: () => dispatch(openLoadingModal()),
  closeModal: (id) => dispatch(closeModal(id)),
});

LendModal.propTypes = {
  address: PropTypes.string,
  lendModal: PropTypes.object,
  showWalletConnectModal: PropTypes.func,
  showLoadingModal: PropTypes.func,
  closeModal: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(LendModal);
