import React, { createContext, useContext, useState, useCallback, useEffect, useRef } from 'react';

import { useWallet } from '@txnlab/use-wallet';

import { useClientContext } from './clientProvider';

import useAccountAssets from 'Hooks/useAccountAssets'

import { useQueryClient } from '@tanstack/react-query';

import useEligibleNFTs from '../hooks/useEligibleNFTs';

import useLoanHistory from '../hooks/useLoanHistory';

export const goannaAssetID = 1387238831;

const WalletContext = createContext(undefined);

// Create provider component
export const WalletProvider = ({ children }) => {
  const queryClient = useQueryClient()
  const { activeAddress } = useWallet();
  const [error, setError] = useState(null);
  const { algodClient } = useClientContext()
  const [reloadWallet, setReloadWallet] = useState(false)
  const toggleReload = () => setReloadWallet(!reloadWallet)
  const { data, isFetching: loading } = useAccountAssets(algodClient, activeAddress)
  const { allAssets, algoBalance, minBalance, goannaBalance, isGoannaHolder } = data || {};
  const { data: eligibleAssetsData, isFetching: isFetchingEAssets, error: eligibleAssetError } = useEligibleNFTs(activeAddress, allAssets)
  const { data: loanHistoryData,
    isFetching: isFetchingLoanHistory,
    error: loanHistoryError } = useLoanHistory(activeAddress)

  const value = {
    isGoannaHolder,
    goannaBalance,
    loading,
    algoBalance,
    minBalance,
    toggleReload,
    reloadWallet,
    activeAddress,
    algodClient,
    allAssets,
    eligibleAssetsData,
    isFetchingEAssets,
    eligibleAssetError,
    loanHistoryData,
    isFetchingLoanHistory,
    loanHistoryError
  };

  return (
    <WalletContext.Provider value={value}>
      {children}
    </WalletContext.Provider>
  );
};

// Custom hook to use the balance context
export const useLocalWallet = () => {
  const context = useContext(WalletContext);
  if (context === undefined) {
    throw new Error('useWallet must be used within a WalletProvider');
  }
  return context;
};